/*global appComponent */
class AppComponent extends appComponent{

    getTemplate(){
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container-fluid">
                      <div class="section-main">
                            <div class="">
                                <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

AppComponent.registerComponent();